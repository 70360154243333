export const questions = [
    {
        question: 'Могу ли я самостоятельно приобрести авто в Европе?',
        answer: 'К сожалению, но сейчас приехать и самостоятельно купить автомобиль в Европе невозможно в связи с ограничением продажи автомобилей россиянам и белорусам, но мы можем помочь вам с приобретением и доставкой до вашего дома, вне зависимости от вашей страны и города'
    },
    {
        question: 'Что такое НЕТТО?',
        answer: 'Нетто – это стоимость авто без европейского налога на добавленную стоимость (НДС). Сумма НДС отличается в зависимости от страны ЕС, поэтому следует быть внимательными. К примеру, в Германии этот налог составляет 19% от стоимости товара, в Литве – 21%, в Польше – 23%.'
    },
    {
        question: 'Что такое БРУТТО?',
        answer: 'Брутто – стоимость авто по НДС. То есть, эта цифра всегда будет больше. Разницу между брутто и нетто можно вернуть, если автомобиль покупает нерезидент страны покупки'
    },
    {
        question: 'За сколько времени мне привезут машину от момента заключения договора?',
        answer: 'Количество времени от начала работы с нами до машины у вашего дома очень сильно зависит от количества вариантов на рынке и когда вам какой-то из этих вариантов понравится, а после того, как мы подберем этот автомобиль и согласуем доставка в среднем занимает 7-10 рабочих дней'
    },
    {
        question: 'Почему стоит выбирать автомобили из Европы?',
        answer: 'В Европе можно найти экономичные, дизельные универсалы в хорошей комплектации, а также уникальные автомобили. Кроме того, европейские авто обслуживались только у дилера и весь пробег прошли по чистым и ровным европейским дорогам и не имеют скрытых дефектов.'
    },
];