export const values = [ 
    {
        text: 'До 3х лет',
        value: '2'
    },
    {
        text: '3-5 лет',
        value: '4'
    },
    {
        text: 'Больше 5 лет',
        value: '6'
    },
];

export const calculatorSum = [
    'Перевод на транзитную фирму (безнал) из Беларуси.',
    'Выкуп автомобиля на транзитную фирму в Польше.',
    'Экспортные номера (транзитные номера).',
    'Страхование Каско.',
    'Доставка автомобиля до Литвы.',
    'Экспортные документы для НДС в Литве, Декларация.',
    'Перевоз через границу в Беларусь (CMR, пломба, оформление ЭПИ, виньнетка, таможенная страховка).',
    'Таможенный налог.',
    'Утилизационный сбор.',
    'Услуги склада и декларантов.',
    'Доставка автомобиля до Москвы (Также можем доставить в любой другой город при необходимости)',
]