export const approach = [
    {
      imgSrc: 'assets/icons/colorClock.svg',
      imgAlt: 'Clock icon',
      title: 'СРОКИ',
      text: 'Выезд специалиста на автомобиль в течении одного дня',
    },
    {
      imgSrc: 'assets/icons/docs.svg',
      imgAlt: 'Docs icon',
      title: 'ДОГОВОР',
      text: 'Официальный договор на доставку автомобиля',
    },
    {
      imgSrc: 'assets/icons/dollar.svg',
      imgAlt: 'Dollar icon',
      title: 'ЭКОНОМИЯ',
      text: 'Торгуемся за вас, ищем лучшую цену на рынке',
    },
  ];