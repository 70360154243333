import React from "react";

export const steps = [
  {
    title: '1. Консультация',
    description: [
      'Мы вместе с вами заполняем заявку и определяемся с машиной которую будем привозить',
    ],
  },
  {
    title: '2. Договор',
    description: [
      'После этого мы заполняем договор на оказание услуг по подбору и сопровождению, заполнить его можно у нас в офисе в Москве или Минске, а также онлайн\n'
    ],
  },
  {
    title: '3. Поиск',
    description: [<>Далее мы начинаем подбор автомобиля на сайте <a href='https://www.mobile.de/' target="_blank">mobile.de</a>, <a
        href='https://www.autoscout24.de/' target="_blank">autoscout.de</a> и
       <a href='https://www.encar.com' target="_blank"> encar.com</a>, предлагаем вам варианты и на понравившиеся
      варианты заказываем выписки по VIN (информация о пробеге, окрасах, залогах, неуплаченных лизингах), также
      проверяем машину через Интерпол не находится ли она в угоне, после чего в дополнении к этому созваниваемся с
      дилером и уточняем детали, чтобы знать информацию на текущий период и эту информацию высылаем вам</>],
  },
  {
    title: '4. Осмотр',
    description: ['Если с машиной никаких проблем нет и вас все устраивает мы выезжаем на место и проводим базовую и компьютерную диагностику авто высылая ее результаты вам посредством фото и видео (порядка 150 фотографий)'],
  },
  {
    title: '5. Предоплата',
    description: ['После того, как находим автомобиль который вам понравился, мы оставляем предоплату и бронируем его, бронь на автомобиль держится порядка 2-3 дней, поэтому после этого в ближайшее время нужно внести предоплату за автомобиль в размере 10% или 100% от суммы. Сделать это можно наличными рублями (мы сами переведем по оптимальному курсу который согласуем с вами) или евро'],
  },
  {
    title: '6. Доставка',
    description: ['Далее мы производим доставку, ее можно произвести несколькими путями:'],
    descriptionItems: ['Германия с клиренсом до 165 (В среднем 14 рабочих дней)', 'Германия с клиренсом от 165 (В среднем 40 рабочих дней)', 'Южная Корея (В среднем 1.5 - 2 месяца)']
  },
  {
    title: '7. Получение автомобиля',
    description: ['Автомобиль приезжает в Москву уже со всем пакетом документов'],
  },
];
