export const statistics = [
  {
    info: '97%',
    text: 'довольных клиентов',
  },
  {
    info: '6',
    text: 'обращений по гарантии',
  },
  {
    info: '2762+',
    text: 'подобранных авто',
  },
  {
    info: '15430+',
    text: 'отсмотренных авто',
  },
];
