import React, { forwardRef } from 'react';
import styles from './calculator.module.scss';
import cn from 'classnames';
import {DeliveryCalculationGermany} from "../DeliveryCalculationGermany/DeliveryCalculationGermany";
import {DeliveryCalculationKorea} from "../DeliveryCalculationKorea/DeliveryCalculationKorea";

interface Props {
    type?: 'europe' | 'korea',
    scrollTo?: (num: number) => void
}

export const Calculator = forwardRef<HTMLDivElement, Props>(({ scrollTo, type }, ref) => {
  return (
    <div className={cn('container', styles.wrapper)}>
      <div className={styles.back}></div>
      <h4 className={styles.title}>сколько стоит привезти ваш автомобиль
          {
              type && (
                  <h4 className={`${type === 'europe' ? styles.europe : styles.korea}`}>{type === 'europe' ? 'европы' : 'южной кореи'}</h4>)
          }
      </h4>
        <div className={styles.calculatorWrap}>
            <DeliveryCalculationGermany scrollTo={scrollTo} ref={ref}/>
            <div className={styles.diviver}></div>
            <DeliveryCalculationKorea scrollTo={scrollTo} ref={ref}/>
        </div>
      </div>
  );
});
