import React, {FC, ForwardedRef, forwardRef, useEffect, useState} from 'react';
import styles from './DeliveryCalculationKorea.module.scss';
import {CustomSelect} from '../customSelect';
import {CustomInput} from '../customInput';
import {CustomRadio} from '../customRadio';
import {ButtonCustom} from '../button';
import {calculatorSum, values} from './DeliveryCalculationKorea.constants';
import {DialogCustom} from '../dialogCustom';
import {CustomCheckBox} from "../customCheckbox";

interface Props {
    scrollTo: (num: number) => void;
}

export const DeliveryCalculationKorea = forwardRef<HTMLDivElement, Props>(({scrollTo}, ref) => {
    const [finalCarPrice, setFinalCarPrice] = useState(0);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        age: '',
        price: '',
        nds: 'Только брутто',
        engine: 'Бензин/дизель',
        volumeEngine: '',
        sellLessThanThreeYears: 'false',
    });
    const [error, setError] = useState(false);
    const calculate_tamozh = (pr, engine_volume, age_group, discount, region) => {
        const engineVolume = parseInt(engine_volume)
        let price = pr;

        if (region == 'germany') {
            price = (price + price / 1.19) / 2

        } else {
            const krw_to_eur_rate = 1 / 0.068;
            price = (price + price / 1.091) / 2 * krw_to_eur_rate
        }

        if (discount) {
            if (age_group < 3) {
                if (price <= 8500) {
                    return Math.max(0.33 * price, 2.5 * engineVolume) + 550
                } else if (price <= 16700) {
                    return Math.max(0.27 * price, 3.5 * engineVolume) + 550
                } else if (price <= 42300) {
                    return Math.max(0.27 * price, 5.5 * engineVolume) + 550
                } else if (price <= 84500) {
                    return Math.max(0.27 * price, 7.5 * engineVolume) + 550
                } else if (price <= 169000) {
                    return Math.max(0.27 * price, 15 * engineVolume) + 550
                } else {
                    return Math.max(0.27 * price, 20 * engineVolume) + 550
                }
            } else if (age_group >= 3 && age_group <= 5) {
                if (engineVolume <= 1000) {
                    return 1.5 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume <= 1500) {
                    return 1.7 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume <= 1800) {
                    return 2.5 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume <= 2300) {
                    return 2.7 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume <= 3000) {
                    return 3.0 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume > 3000) {
                    return 3.6 * engineVolume / 2 + 1000 + 450
                }
            } else {
                if (engineVolume <= 1000) {
                    return 3 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume <= 1500) {
                    return 3.2 * engineVolume / 2 + 1000 + 450
                } else if (engineVolume <= 1800) {
                    return 3.5 * engineVolume / 2 + 1000 + 450

                } else if (engineVolume <= 2300) {
                    return 4.8 * engineVolume / 2 + 1000 + 450

                } else if (engineVolume <= 3000) {
                    return 5 * engineVolume / 2 + 1000 + 450

                } else {
                    return 5.7 * engineVolume / 2 + 1000 + 450
                }
            }
        } else {
            if (age_group < 3) {
                if (price <= 8500) {
                    return Math.max(0.54 * price, 2.5 * engineVolume)
                } else if (price <= 16700) {
                    return Math.max(0.48 * price, 3.5 * engineVolume)
                } else if (price <= 42300) {
                    return Math.max(0.48 * price, 5.5 * engineVolume)
                } else if (price <= 84500) {
                    return Math.max(0.48 * price, 7.5 * engineVolume)
                } else if (price <= 169000) {
                    return Math.max(0.48 * price, 15 * engineVolume)
                } else {
                    return Math.max(0.48 * price, 20 * engineVolume)
                }
            } else if (age_group >= 3 && age_group <= 5) {
                if (engineVolume <= 1000) {
                    return 1.5 * engineVolume
                } else if (engineVolume <= 1500) {
                    return 1.7 * engineVolume
                } else if (engineVolume <= 1800) {
                    return 2.5 * engineVolume

                } else if (engineVolume <= 2300) {
                    return 2.7 * engineVolume
                } else if (engineVolume <= 3000) {
                    return 3.0 * engineVolume

                } else if (engineVolume > 3000) {
                    return 3.6 * engineVolume
                }
            } else {
                if (engineVolume <= 1000) {
                    return 3 * engineVolume
                } else if (engineVolume <= 1500) {
                    return 3.2 * engineVolume

                } else if (engineVolume <= 1800) {
                    return 3.5 * engineVolume

                } else if (engineVolume <= 2300) {
                    return 4.8 * engineVolume
                } else if (engineVolume <= 3000) {
                    return 5 * engineVolume
                } else {
                    return 5.7 * engineVolume
                }
            }
        }
    }

    const calculate_utilization_fee = (engineVolume, age_group) => {
        const engine_volume = parseInt(engineVolume);
        if (age_group < 3) {
            if (engine_volume <= 1000) {
                return 3400
            } else if (engine_volume <= 2000) {
                return 3400
            } else if (engine_volume <= 3000) {
                return 3400
            } else if (engine_volume <= 3500) {
                return 970000
            } else return 1235200
        } else {
            if (engine_volume <= 1000) {
                return 5200
            } else if (engine_volume <= 2000) {
                return 5200

            } else if (engine_volume <= 3000) {
                return 5200

            } else if (engine_volume <= 3500) {
                return 1485000
            } else return 1623800
        }
    }

    const round_des = (value) => {
        return Math.ceil(value / 10000.0) * 10000
    }

    const result = (price, engine_volume, age_group, clearance) => {
        const {
            total_discount_cost,
            total_full_cost,
            korea_rate
        } = calculateCarCostKorea(parseInt(price), engine_volume, age_group, clearance)


        let final_disc = round_des(total_discount_cost)
        let final_full = round_des(total_full_cost)

        if (final_disc < 10000000) {
            final_disc *= 1.1
            final_disc += 200000
        } else if (final_disc < 20000000) {
            final_disc *= 1.08
            final_disc += 200000
        } else if (final_disc < 38000000) {
            final_disc *= 1.07
            final_disc += 200000
        } else {
            final_disc *= 1.04
            final_disc += 200000
        }

        if (final_full < 10000000) {
            final_full *= 1.1
        } else if (final_full < 20000000) {
            final_full *= 1.08
        } else if (final_full < 38000000) {
            final_full *= 1.07
        } else {
            final_full *= 1.04
        }

        setFinalCarPrice(final_full / 100)
    }


    const calculateCarCostKorea = (price, engine_volume, age_group, category) => {
        const korea_rate = 0.068 / 1.02
        const euro_rate = 100;
        const discountCost = calculate_tamozh(price, engine_volume, age_group, true, 'korea');
        const fullCost = calculate_tamozh(price, engine_volume, age_group, false, 'korea')
        const utilization_fee = calculate_utilization_fee(engine_volume, age_group)

        const discount_tamozh_rub = discountCost * euro_rate
        const full_tamozh_rub = fullCost * euro_rate

        let total_discount_cost = (price * 0.9670671 + 1940000) * korea_rate +
            (engine_volume < 3000 ? 285000 + 70000 : 0.01 * full_tamozh_rub + 44000 + utilization_fee) + discount_tamozh_rub;

        let total_full_cost = (price * 0.9670671 + 1940000) * korea_rate +
            (220000 + 95000 + utilization_fee) + full_tamozh_rub;

        return {total_discount_cost, total_full_cost, korea_rate}
    }


    const handleClickOpen = () => {
        if (
            form.age !== '' &&
            form.price !== '' &&
            form.volumeEngine !== ''
        ) {
            setError(false);
            result(form.price, form.volumeEngine, form.age, true)

            setOpen(true);
        } else {
            setError(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!open) {
            setFinalCarPrice(0);
        }
    }, [open]);

    const onChange = (fieldId: string, value: string) => {
        if (value === 'Электро') {
            setForm({
                ...form,
                [fieldId]: value,
            });
            return
        }
        setForm({
            ...form,
            [fieldId]: value,
        });
    };


    return (
        <>
            <div className={styles.anchor} ref={ref}></div>
            <div className={styles.wrapper}>
                <h1 style={{textAlign: "center"}}>Из Кореи 🇰🇷</h1>
                <CustomSelect
                    id='age'
                    title='Возраст автомобиля'
                    options={values}
                    value={form.age}
                    error={form.age === '' && error}
                    onChange={onChange}
                />
                <CustomInput
                    title='Стоимость автомобиля'
                    placeholder=' Введите сумму нетто в вонах'
                    id='price'
                    value={form.price}
                    error={form.price === '' && error}
                    onChnage={onChange}
                />
                <CustomInput
                    id='volumeEngine'
                    value={form.volumeEngine}
                    title='Объем двигателя'
                    placeholder='См 3'
                    error={form.volumeEngine === '' && error}
                    onChnage={onChange}
                />
                <ButtonCustom
                    text='рассчитать'
                    textTransform='uppercase'
                    fontWeight={700}
                    color
                    className={styles.btn}
                    onClick={handleClickOpen}
                />
            </div>
            <DialogCustom
                open={open}
                onClose={handleClose}
                sx={{
                    padding: {
                        sm: '16px',
                        md: '50px',
                        lg: '40px',
                        xl: '40px',
                    },
                    maxWidth: '1146px',
                    borderRadius: '30px',
                    border: '4px solid #19FB9B',
                    boxShadow: '0px 0px 50px 5px rgba(184, 184, 184, 0.25)',
                }}
            >
                <div className={styles.dialogWrap}>
                    <div className={styles.titleWrap}>
                        <div/>
                        <span className={styles.title}>стоимость авто под ключ</span>
                        <button className={styles.btn} onClick={handleClose}>
                            <img src='assets/icons/close.svg' alt='Icon'/>
                        </button>
                    </div>
                    <span className={styles.price}>{finalCarPrice.toFixed(2)} €</span>
                    <div className={styles.description}>
            <span className={styles.textUpper}>
              На выходе вы получаете готовый автомобиль в Москве.
            </span>
                        <span>
              {`Вам остается его поставить на учет в России или везти в другую
              страну для постановки на учет.`}
            </span>
                    </div>
                    <div className={styles.totalWrap}>
            <span className={styles.totalTitle}>
              {`Итого автомобиль выходит в Москве под ключ со всем перечнем услуг:`}
            </span>
                        <ul className={styles.totalList}>
                            {
                                calculatorSum.map((item) => (
                                    <li key={item} className={styles.totalListItem}>
                                        {item}
                                    </li>
                                ))
                            }
                        </ul>
                        <span className={styles.starText}>* Расчет стоимости автомобиля под ключ является приблизительным</span>
                    </div>
                    <ButtonCustom
                        text='связаться с нами'
                        fontWeight={700}
                        textTransform='uppercase'
                        color
                        className={styles.btn}
                        onClick={() => {
                            handleClose();
                            scrollTo(3);
                        }}
                    />
                </div>
            </DialogCustom>
        </>
    );
});
